import React from 'react';
import {ingCardContainer,ingCardImage,placeHolder,ingCardImageContainer,ingCardText} from './index.module.scss';
const BlankCard = () => {


    return (

        <button disabled={true}  className={`${ingCardContainer} ${placeHolder}`}>
            <div className={ingCardImageContainer}>
            <img src={''} className={`${ingCardImage}`  }/>         
            </div>
            <p className={ingCardText}>{}</p>
            <p className={ingCardText}>{}</p>

            
        </button>
    );

}


export default BlankCard;