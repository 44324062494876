import React from 'react';
import {topBun, bottomBun, midBun} from './index.module.scss';

interface BurgerBuilderBlockProps  {
    title: string,
    type :string
}

const BurgerBuilderBlock = (props: BurgerBuilderBlockProps) => {

    let burgerType = "";

    switch(props.type){
        case "TOP BUN": {
            burgerType = topBun
            break;
        }
        case "BOTTOM BUN": {
            burgerType = bottomBun;
            break;
        }
        case "MIDDLE BUN": {
            burgerType = midBun;
            break;
        }
        case "BUN": {
            burgerType= midBun;
            break;
        }
        case "TOPPING": {
            burgerType= midBun;
            break;
        }
        case "MEAT":{
            burgerType= midBun;
            break;
        }
    


    } 
    return <div className={burgerType}>{props.title}</div>
}


export default BurgerBuilderBlock;